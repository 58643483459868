@import "../foundation/setting";



/*common
---------------------------------------------------------- */
html {
	font-size: 62.5%;
}

body {
	font-family: 'メイリオ',Meiryo,'Hiragino Kaku Gothic Pro','ＭＳ Ｐゴシック',sans-serif;
	color: #333;
	font-size: 1.4rem;
	line-height: 1.6;
	word-wrap: break-word;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	background: url("../img/common/bg.jpg") repeat;
	@include mq('min') {
		min-width: 960px;
	}
	@include mq('max') {
		font-size: 1.2rem;
	}
}

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon/icomoon.eot?etnesh');
	src:  url('../fonts/icomoon/icomoon.eot?etnesh#iefix') format('embedded-opentype'),
	url('../fonts/icomoon/icomoon.ttf?etnesh') format('truetype'),
	url('../fonts/icomoon/icomoon.woff?etnesh') format('woff'),
	url('../fonts/icomoon/icomoon.svg?etnesh#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}