@import "../foundation/setting";


.l-footer {
	@include mq('max') {
		padding: 20px 15px;
		background: #F5F5F5;
	}
} 

.l-footer__top {
	@include mq('min') {
		padding: 5px 0;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		background: #F5F5F5;
	}
	@include mq('max') {
		margin-bottom: 25px;
	}
}

.l-footer__top-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 940px;
	margin: auto;
}

.l-footer__bottom {
	@include mq('min') {
		padding: 5px 0;
	}
}

.l-footer__bottom-inner {
	max-width: 940px;
	margin: auto;
	@include mq('min') {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.l-footer__nav-items {
	display: flex;
	flex-wrap: wrap;
	@include mq('max') {
		position: relative;
		left: 1px;
		width: calc(100% + 1px);
	}
}

.l-footer__nav-item {
	@include mq('min') {
		font-size: 1rem;
		font-weight: bold;
		&:not(:last-child) {
			margin-right: 15px;
			padding-right: 15px;
			border-right:1px solid #dedede;
		}
		.item__link {
			&:hover {
			opacity: .6;
			}
		}
	}
	@include mq('max') {
		width: calc(50% + 1px);
		margin: -1px 0 0 -1px;
		&:first-child {
			display: none;
		}
		.item__link {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px;
			text-align: center;
			border: 1px solid #ccc;
		}
	}
}

.l-footer__bnr-items {
	@include mq('max') {
		display: none;
	}
}

.l-footer__info {
	&[data-device="pc"] {
		background:url("../img/common/footer__info.png") left top no-repeat;
		width:255px;
		height:96px;
		text-indent:-9999px;
		overflow: hidden;
		@include mq('max') {
			display: none;
		}
	}
	&[data-device="sp"] {
		@include mq('min') {
			display: none;
		}
		.l-footer__info-logo {
			width: 120px;
			margin: auto;
			margin-bottom: 10px;
		}
		.l-footer__info-address {
			margin-bottom: 10px;
			text-align: center;
			p {
				display: inline-block;
				text-align: left;
			}
		}
		.l-footer__info-tel {
			text-align: center;
			font-weight: bold;
		}
	}
}

.l-footer__copy {
	background: url("../img/common/copyright.png") left top no-repeat;
	width:256px;
	height:13px;
	font-size: 10px;
	line-height:10px;
	text-indent:-9999px;
	overflow: hidden;
	@include mq('max') {
		display: none;
	}
}