@import "../foundation/setting";




/*icon
---------------------------------------------------------- */
[class^="c-icon__"],
[class*="c-icon__"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.c-icon__arrow-left:before {
	content: icon($_icon_arrow_left);
}

.c-icon__arrow-right:before {
	content: icon($_icon_arrow_right);
}



/*content
---------------------------------------------------------- */
.c-content {
	padding: 50px 0 20px;
	@include mq('max') {
		padding: 25px 15px 30px;
		&[data-sp="full__width"] {
			padding-left: 0;
			padding-right: 0;
		}
	}
}



/*fit
---------------------------------------------------------- */
.c-fit {
	max-width: 940px;
	margin: auto;
}



/*visually-hidden
---------------------------------------------------------- */
.c-visually__hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}



/*page__title
---------------------------------------------------------- */
.c-page__title {
	display: flex;
	min-height: 98px;
	padding: 0 15px;
	color: #fff;
	background:#008cd6;
	@include mq('max') {
		min-height: 60px;
	}
}

.c-page__title-inner {
	display: flex;
	align-items: center;
	max-width: 940px;
	width: 100%;
	margin:auto;
	min-height: inherit;
	height: 100%;
	padding: 5px 0;
	@include mq('min') {
		background: url("../img/common/page-title__bg.png") bottom right no-repeat;
	}
	@include mq('max') {
		justify-content: center;
	}
}

.c-page__title-jp {
	font-size: 2rem;
	@include mq('min') {
		font-weight: bold;
	}
	@include mq('max') {
		font-size: 1.8rem;
	}
}

.c-page__title-en {
	font-size: 1.5rem;
	&:before {
		content: "/";
		display: inline-block;
		margin-left: 10px;
		padding-right: 5px;
	}
	@include mq('max') {
		display: none;
	}
}



/*page__top
---------------------------------------------------------- */
.c-page__top {
	padding-top: 20px;
	text-align: right;
}



/*breadcrumb
---------------------------------------------------------- */
.c-breadcrumb {
	padding-top: 10px;
	@include mq(max) {
		display: none;
	}
}

.c-breadcrumb__items {
	display: flex;
	white-space: nowrap;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.c-breadcrumb__item {
	margin-right: 5px;
	font-size: 1rem;
	&:first-child {
		.item__link {
			padding-left: 15px;
			background: url("../img/common/breadcrumb__home.png") left top 2px no-repeat;
		}
	}
	&:not(:last-child):after {
		content: "»";
		margin-left: 5px;
	}
	.item__link {
		display: inline-block;
		color: $_color_primary_dark;
		text-decoration: underline;
		@include mq(min) {
			&:hover {
				color: $_color_secondary;
				text-decoration: none;
			}
		}
	}
}



/*heading
---------------------------------------------------------- */
.c-heading__lv1 {
	margin-bottom: 20px;
	font-size: 2.8rem;
	color:$_color_primary_dark;
	@include mq('max') {
		margin-bottom: 10px;
		font-size: 1.5rem;
		font-weight: bold;
	}
}

.c-heading__lv2 {
	margin-bottom: 15px;
	padding: 0 0 6px 25px;
	font-size: 1.6rem;
	font-weight: bold;
	border-bottom: 1px solid #e5e5e5;
	background: url("../img/common/heading__lv2-bg.png") no-repeat;
	background-position: top 4px left;
	@include mq('max') {
		margin-bottom: 10px;
		font-size: 1.3rem;
		background-position: top 2px left;
	}
}

.c-heading__lv3 {
	margin-bottom: 10px;
	padding-left: 18px;
	font-weight:bold;
	background: url("../img/common/heading__lv3-bg.png") no-repeat;
	background-position: top 6px left;
	@include mq('max') {
		margin-bottom: 5px;
		background-position: top 5px left;
	}
}





/*table
---------------------------------------------------------- */
.c-table {
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
}

.c-table th,
.c-table td {
	vertical-align: top;
	padding: 10px 10px 6px;
	text-align: left;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.c-table th {
	width: 26%;
	vertical-align: middle;
	font-weight: bold;
	background: #fef1dd;
}




/*link
---------------------------------------------------------- */
.c-link {
	color: $_color_primary_dark;
	text-decoration: underline;
	@include mq('min') {
		&:hover {
			color: $_color_secondary;
			text-decoration: none;
		}
	}
}




/*indent
---------------------------------------------------------- */
.c-indent {
	margin-left: 1em;
	text-indent: -1em;
}


/*expands
---------------------------------------------------------- */
.c-expands {
	.c-expand:last-child {
		margin-bottom: 0;
	}
}

.c-expand {
	margin-bottom: 40px;
	@include mq('max') {
		margin-bottom: 20px;
	}
}


/*text
---------------------------------------------------------- */
.c-text > * {
	&:not(:first-child) {
		margin-top: 10px;
	}
}

.c-text * {
	li {
		&:not(:first-child) {
			margin-top: 5px;
		}
	}
}



/*border-box
---------------------------------------------------------- */
.c-border-box {
	padding: 5px 10px;
	border: 1px solid #ccc;
}


/*check
---------------------------------------------------------- */
.c-check__item {
	margin-bottom: 5px;
	padding: 0 5px 5px 20px;
	background: url("../img/common/icon__check.png") 0 3px no-repeat;
}