// ----------------------------------------
// color
// ----------------------------------------
$_color_primary          : #008CD6;
$_color_primary_dark     : #005480;
$_color_secondary        : #F39800;


// ----------------------------------------
// icon
// ----------------------------------------
@function icon($character){
	@return unquote('\"') + unquote(str-insert($character,'\\', 1)) + unquote('\"');
}
$_icon_arrow_left         : "e900";
$_icon_arrow_right        : "e901";


// ----------------------------------------
// breakpoints
// ----------------------------------------
$breakpoints: (
	'sm': 320,
	'sp': 750,
	'lg': 1024,
	'xl': 1280,
	'xxl': 1440,
) !default;
 
@mixin mq($mq, $bp1: sp, $bp2: lg) {
	$w1    : map-get($breakpoints, $bp1);
	$w2    : map-get($breakpoints, $bp2);
	$min1  : 'min-width: #{convert-em($w1, 1)}';
	$min2  : 'min-width: #{convert-em($w1, 1)}';
	$max1  : 'max-width: #{convert-em($w1)}';
	$max2  : 'max-width: #{convert-em($w2)}';

	@if $mq == min {
		@media screen and ($min1) {
			@content;
		}
	}
	@else if $mq == max {
		@media screen and ($max1) {
			@content;
		}
	}
	@else if $mq == min-max {
		@media screen and ($min2) and ($max2) {
			@content;
		}
	}
}
@function convert-em($width, $ge: 0) {
	@return '#{($width + $ge) / 16}em';
}


// ----------------------------------------
// fonts
// ----------------------------------------
@mixin icomoon() {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// ----------------------------------------
// img
// ----------------------------------------
@mixin cover() {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	font-family: 'object-fit: cover; object-position: center;';
}



