@import "../foundation/setting";



/*表示・非表示管理
---------------------------------------------------------- */
/* 非表示 */
.u-disp-none {
	display: none;
}

/* PC非表示 */
.u-sp-none {
	display: block !important;
}

/* SP非表示 */
.u-pc-none {
	display: none !important;
}



@media screen and (max-width: 750px) {
	
	.u-sp-none {
		display: none !important;
	}
	
	.u-pc-none {
		display: block !important;
	}
	
	.u-disp-sp-none {
		display: none;
	}

}



/*width
---------------------------------------------------------- */
@include mq('min') {
	.u-pc-w10  { width:  10px !important; }
	.u-pc-w20  { width:  20px !important; }
	.u-pc-w30  { width:  30px !important; }
	.u-pc-w40  { width:  40px !important; }
	.u-pc-w50  { width:  50px !important; }
	.u-pc-w60  { width:  60px !important; }
	.u-pc-w70  { width:  70px !important; }
	.u-pc-w80  { width:  80px !important; }
	.u-pc-w90  { width:  90px !important; }
	.u-pc-w100 { width: 100px !important; }
	.u-pc-w110 { width: 110px !important; }
	.u-pc-w120 { width: 120px !important; }
	.u-pc-w130 { width: 130px !important; }
	.u-pc-w140 { width: 140px !important; }
	.u-pc-w150 { width: 150px !important; }
	.u-pc-w160 { width: 160px !important; }
	.u-pc-w170 { width: 170px !important; }
	.u-pc-w180 { width: 180px !important; }
	.u-pc-w190 { width: 190px !important; }
	.u-pc-w200 { width: 200px !important; }
	.u-pc-w210 { width: 210px !important; }
	.u-pc-w220 { width: 220px !important; }
	.u-pc-w230 { width: 230px !important; }
	.u-pc-w240 { width: 240px !important; }
	.u-pc-w250 { width: 250px !important; }
	.u-pc-w260 { width: 260px !important; }
	.u-pc-w270 { width: 270px !important; }
	.u-pc-w280 { width: 280px !important; }
	.u-pc-w290 { width: 290px !important; }
	.u-pc-w300 { width: 300px !important; }
	.u-pc-w310 { width: 310px !important; }
	.u-pc-w320 { width: 320px !important; }
	.u-pc-w330 { width: 330px !important; }
	.u-pc-w340 { width: 340px !important; }
	.u-pc-w350 { width: 350px !important; }
	.u-pc-w360 { width: 360px !important; }
	.u-pc-w370 { width: 370px !important; }
	.u-pc-w380 { width: 380px !important; }
	.u-pc-w390 { width: 390px !important; }
	.u-pc-w400 { width: 400px !important; }
	.u-pc-w410 { width: 410px !important; }
	.u-pc-w420 { width: 420px !important; }
	.u-pc-w430 { width: 430px !important; }
	.u-pc-w440 { width: 440px !important; }
	.u-pc-w450 { width: 450px !important; }
	.u-pc-w460 { width: 460px !important; }
	.u-pc-w470 { width: 470px !important; }
	.u-pc-w480 { width: 480px !important; }
	.u-pc-w490 { width: 490px !important; }
	.u-pc-w500 { width: 500px !important; }
	.u-pc-w510 { width: 510px !important; }
	.u-pc-w520 { width: 520px !important; }
	.u-pc-w530 { width: 530px !important; }
	.u-pc-w540 { width: 540px !important; }
	.u-pc-w550 { width: 550px !important; }
	.u-pc-w560 { width: 560px !important; }
	.u-pc-w570 { width: 570px !important; }
	.u-pc-w580 { width: 580px !important; }
	.u-pc-w590 { width: 590px !important; }
	.u-pc-w600 { width: 600px !important; }
	.u-pc-w610 { width: 610px !important; }
	.u-pc-w620 { width: 620px !important; }
	.u-pc-w630 { width: 630px !important; }
	.u-pc-w640 { width: 640px !important; }
	.u-pc-w650 { width: 650px !important; }
	.u-pc-w660 { width: 660px !important; }
	.u-pc-w670 { width: 670px !important; }
	.u-pc-w680 { width: 680px !important; }
	.u-pc-w690 { width: 690px !important; }
	.u-pc-w700 { width: 700px !important; }
	.u-pc-w710 { width: 710px !important; }
	.u-pc-w720 { width: 720px !important; }
	.u-pc-w730 { width: 730px !important; }
	.u-pc-w740 { width: 740px !important; }
	.u-pc-w750 { width: 750px !important; }
	.u-pc-w760 { width: 760px !important; }
	.u-pc-w770 { width: 770px !important; }
	.u-pc-w780 { width: 780px !important; }
	.u-pc-w790 { width: 790px !important; }
	.u-pc-w800 { width: 800px !important; }
	.u-pc-w810 { width: 810px !important; }
	.u-pc-w820 { width: 820px !important; }
	.u-pc-w830 { width: 830px !important; }
	.u-pc-w840 { width: 840px !important; }
	.u-pc-w850 { width: 850px !important; }
	.u-pc-w860 { width: 860px !important; }
	.u-pc-w870 { width: 870px !important; }
	.u-pc-w880 { width: 880px !important; }
	.u-pc-w890 { width: 890px !important; }
	.u-pc-w900 { width: 900px !important; }
	.u-pc-w910 { width: 910px !important; }
	.u-pc-w920 { width: 920px !important; }
	.u-pc-w930 { width: 930px !important; }
	.u-pc-w940 { width: 940px !important; }
	.u-pc-w950 { width: 950px !important; }
	.u-pc-w960 { width: 960px !important; }
	.u-pc-w970 { width: 970px !important; }
	.u-pc-w980 { width: 980px !important; }
	.u-pc-w990 { width: 990px !important; }
	.u-pc-w1000 { width: 1000px !important; }
}



.u-w1per  { width:  1% !important; }
.u-w2per  { width:  2% !important; }
.u-w3per  { width:  3% !important; }
.u-w4per  { width:  4% !important; }
.u-w5per  { width:  5% !important; }
.u-w6per  { width:  6% !important; }
.u-w7per  { width:  7% !important; }
.u-w8per  { width:  8% !important; }
.u-w9per  { width:  9% !important; }
.u-w10per { width: 10% !important; }
.u-w11per { width: 11% !important; }
.u-w12per { width: 12% !important; }
.u-w13per { width: 13% !important; }
.u-w14per { width: 14% !important; }
.u-w15per { width: 15% !important; }
.u-w16per { width: 16% !important; }
.u-w17per { width: 17% !important; }
.u-w18per { width: 18% !important; }
.u-w19per { width: 19% !important; }
.u-w20per { width: 20% !important; }
.u-w21per { width: 21% !important; }
.u-w22per { width: 22% !important; }
.u-w23per { width: 23% !important; }
.u-w24per { width: 24% !important; }
.u-w25per { width: 25% !important; }
.u-w26per { width: 26% !important; }
.u-w27per { width: 27% !important; }
.u-w28per { width: 28% !important; }
.u-w29per { width: 29% !important; }
.u-w30per { width: 30% !important; }
.u-w31per { width: 31% !important; }
.u-w32per { width: 32% !important; }
.u-w33per { width: 33% !important; }
.u-w34per { width: 34% !important; }
.u-w35per { width: 35% !important; }
.u-w36per { width: 36% !important; }
.u-w37per { width: 37% !important; }
.u-w38per { width: 38% !important; }
.u-w39per { width: 39% !important; }
.u-w40per { width: 40% !important; }
.u-w41per { width: 41% !important; }
.u-w42per { width: 42% !important; }
.u-w43per { width: 43% !important; }
.u-w44per { width: 44% !important; }
.u-w45per { width: 45% !important; }
.u-w46per { width: 46% !important; }
.u-w47per { width: 47% !important; }
.u-w48per { width: 48% !important; }
.u-w49per { width: 49% !important; }
.u-w50per { width: 50% !important; }
.u-w51per { width: 51% !important; }
.u-w52per { width: 52% !important; }
.u-w53per { width: 53% !important; }
.u-w54per { width: 54% !important; }
.u-w55per { width: 55% !important; }
.u-w56per { width: 56% !important; }
.u-w57per { width: 57% !important; }
.u-w58per { width: 58% !important; }
.u-w59per { width: 59% !important; }
.u-w60per { width: 60% !important; }
.u-w61per { width: 61% !important; }
.u-w62per { width: 62% !important; }
.u-w63per { width: 63% !important; }
.u-w64per { width: 64% !important; }
.u-w65per { width: 65% !important; }
.u-w66per { width: 66% !important; }
.u-w67per { width: 67% !important; }
.u-w68per { width: 68% !important; }
.u-w69per { width: 69% !important; }
.u-w70per { width: 70% !important; }
.u-w71per { width: 71% !important; }
.u-w72per { width: 72% !important; }
.u-w73per { width: 73% !important; }
.u-w74per { width: 74% !important; }
.u-w75per { width: 75% !important; }
.u-w76per { width: 76% !important; }
.u-w77per { width: 77% !important; }
.u-w78per { width: 78% !important; }
.u-w79per { width: 79% !important; }
.u-w80per { width: 80% !important; }
.u-w81per { width: 81% !important; }
.u-w82per { width: 82% !important; }
.u-w83per { width: 83% !important; }
.u-w84per { width: 84% !important; }
.u-w85per { width: 85% !important; }
.u-w86per { width: 86% !important; }
.u-w87per { width: 87% !important; }
.u-w88per { width: 88% !important; }
.u-w89per { width: 89% !important; }
.u-w90per { width: 90% !important; }
.u-w91per { width: 91% !important; }
.u-w92per { width: 92% !important; }
.u-w93per { width: 93% !important; }
.u-w94per { width: 94% !important; }
.u-w95per { width: 95% !important; }
.u-w96per { width: 96% !important; }
.u-w97per { width: 97% !important; }
.u-w98per { width: 98% !important; }
.u-w99per { width: 99% !important; }
.u-w100per{ width: 100% !important; }
.u-wauto  { width: auto !important; }


@include mq('max') {
	
	.u-sp-w10  { width:  10px !important; }
	.u-sp-w20  { width:  20px !important; }
	.u-sp-w30  { width:  30px !important; }
	.u-sp-w40  { width:  40px !important; }
	.u-sp-w50  { width:  50px !important; }
	.u-sp-w60  { width:  60px !important; }
	.u-sp-w70  { width:  70px !important; }
	.u-sp-w80  { width:  80px !important; }
	.u-sp-w90  { width:  90px !important; }
	.u-sp-w100 { width: 100px !important; }
	.u-sp-w110 { width: 110px !important; }
	.u-sp-w120 { width: 120px !important; }
	.u-sp-w130 { width: 130px !important; }
	.u-sp-w140 { width: 140px !important; }
	.u-sp-w150 { width: 150px !important; }
	.u-sp-w160 { width: 160px !important; }
	.u-sp-w170 { width: 170px !important; }
	.u-sp-w180 { width: 180px !important; }
	.u-sp-w190 { width: 190px !important; }
	.u-sp-w200 { width: 200px !important; }
	.u-sp-w210 { width: 210px !important; }
	.u-sp-w220 { width: 220px !important; }
	.u-sp-w230 { width: 230px !important; }
	.u-sp-w240 { width: 240px !important; }
	.u-sp-w250 { width: 250px !important; }
	.u-sp-w260 { width: 260px !important; }
	.u-sp-w270 { width: 270px !important; }
	.u-sp-w280 { width: 280px !important; }
	.u-sp-w290 { width: 290px !important; }
	.u-sp-w300 { width: 300px !important; }
	.u-sp-w310 { width: 310px !important; }
	.u-sp-w320 { width: 320px !important; }
	.u-sp-w330 { width: 330px !important; }
	.u-sp-w340 { width: 340px !important; }
	.u-sp-w350 { width: 350px !important; }
	.u-sp-w360 { width: 360px !important; }
	.u-sp-w370 { width: 370px !important; }
	.u-sp-w380 { width: 380px !important; }
	.u-sp-w390 { width: 390px !important; }
	.u-sp-w400 { width: 400px !important; }
	.u-sp-w410 { width: 410px !important; }
	.u-sp-w420 { width: 420px !important; }
	.u-sp-w430 { width: 430px !important; }
	.u-sp-w440 { width: 440px !important; }
	.u-sp-w450 { width: 450px !important; }
	.u-sp-w460 { width: 460px !important; }
	.u-sp-w470 { width: 470px !important; }
	.u-sp-w480 { width: 480px !important; }
	.u-sp-w490 { width: 490px !important; }
	.u-sp-w500 { width: 500px !important; }
	.u-sp-w510 { width: 510px !important; }
	.u-sp-w520 { width: 520px !important; }
	.u-sp-w530 { width: 530px !important; }
	.u-sp-w540 { width: 540px !important; }
	.u-sp-w550 { width: 550px !important; }
	.u-sp-w560 { width: 560px !important; }
	.u-sp-w570 { width: 570px !important; }
	.u-sp-w580 { width: 580px !important; }
	.u-sp-w590 { width: 590px !important; }
	.u-sp-w600 { width: 600px !important; }
	.u-sp-w610 { width: 610px !important; }
	.u-sp-w620 { width: 620px !important; }
	.u-sp-w630 { width: 630px !important; }
	.u-sp-w640 { width: 640px !important; }
	.u-sp-w650 { width: 650px !important; }
	.u-sp-w660 { width: 660px !important; }
	.u-sp-w670 { width: 670px !important; }
	.u-sp-w680 { width: 680px !important; }
	.u-sp-w690 { width: 690px !important; }
	.u-sp-w700 { width: 700px !important; }
	.u-sp-w710 { width: 710px !important; }
	.u-sp-w720 { width: 720px !important; }
	.u-sp-w730 { width: 730px !important; }
	.u-sp-w740 { width: 740px !important; }
	.u-sp-w750 { width: 750px !important; }
	.u-sp-w760 { width: 760px !important; }
	.u-sp-w770 { width: 770px !important; }
	.u-sp-w780 { width: 780px !important; }
	.u-sp-w790 { width: 790px !important; }
	.u-sp-w800 { width: 800px !important; }
	.u-sp-w810 { width: 810px !important; }
	.u-sp-w820 { width: 820px !important; }
	.u-sp-w830 { width: 830px !important; }
	.u-sp-w840 { width: 840px !important; }
	.u-sp-w850 { width: 850px !important; }
	.u-sp-w860 { width: 860px !important; }
	.u-sp-w870 { width: 870px !important; }
	.u-sp-w880 { width: 880px !important; }
	.u-sp-w890 { width: 890px !important; }
	.u-sp-w900 { width: 900px !important; }
	.u-sp-w910 { width: 910px !important; }
	.u-sp-w920 { width: 920px !important; }
	.u-sp-w930 { width: 930px !important; }
	.u-sp-w940 { width: 940px !important; }
	.u-sp-w950 { width: 950px !important; }
	.u-sp-w960 { width: 960px !important; }
	.u-sp-w970 { width: 970px !important; }
	.u-sp-w980 { width: 980px !important; }
	.u-sp-w990 { width: 990px !important; }
	.u-sp-w1000 { width: 1000px !important; }

	.u-sp-w1per  { width:  1% !important; }
	.u-sp-w2per  { width:  2% !important; }
	.u-sp-w3per  { width:  3% !important; }
	.u-sp-w4per  { width:  4% !important; }
	.u-sp-w5per  { width:  5% !important; }
	.u-sp-w6per  { width:  6% !important; }
	.u-sp-w7per  { width:  7% !important; }
	.u-sp-w8per  { width:  8% !important; }
	.u-sp-w9per  { width:  9% !important; }
	.u-sp-w10per { width: 10% !important; }
	.u-sp-w11per { width: 11% !important; }
	.u-sp-w12per { width: 12% !important; }
	.u-sp-w13per { width: 13% !important; }
	.u-sp-w14per { width: 14% !important; }
	.u-sp-w15per { width: 15% !important; }
	.u-sp-w16per { width: 16% !important; }
	.u-sp-w17per { width: 17% !important; }
	.u-sp-w18per { width: 18% !important; }
	.u-sp-w19per { width: 19% !important; }
	.u-sp-w20per { width: 20% !important; }
	.u-sp-w21per { width: 21% !important; }
	.u-sp-w22per { width: 22% !important; }
	.u-sp-w23per { width: 23% !important; }
	.u-sp-w24per { width: 24% !important; }
	.u-sp-w25per { width: 25% !important; }
	.u-sp-w26per { width: 26% !important; }
	.u-sp-w27per { width: 27% !important; }
	.u-sp-w28per { width: 28% !important; }
	.u-sp-w29per { width: 29% !important; }
	.u-sp-w30per { width: 30% !important; }
	.u-sp-w31per { width: 31% !important; }
	.u-sp-w32per { width: 32% !important; }
	.u-sp-w33per { width: 33% !important; }
	.u-sp-w34per { width: 34% !important; }
	.u-sp-w35per { width: 35% !important; }
	.u-sp-w36per { width: 36% !important; }
	.u-sp-w37per { width: 37% !important; }
	.u-sp-w38per { width: 38% !important; }
	.u-sp-w39per { width: 39% !important; }
	.u-sp-w40per { width: 40% !important; }
	.u-sp-w41per { width: 41% !important; }
	.u-sp-w42per { width: 42% !important; }
	.u-sp-w43per { width: 43% !important; }
	.u-sp-w44per { width: 44% !important; }
	.u-sp-w45per { width: 45% !important; }
	.u-sp-w46per { width: 46% !important; }
	.u-sp-w47per { width: 47% !important; }
	.u-sp-w48per { width: 48% !important; }
	.u-sp-w49per { width: 49% !important; }
	.u-sp-w50per { width: 50% !important; }
	.u-sp-w51per { width: 51% !important; }
	.u-sp-w52per { width: 52% !important; }
	.u-sp-w53per { width: 53% !important; }
	.u-sp-w54per { width: 54% !important; }
	.u-sp-w55per { width: 55% !important; }
	.u-sp-w56per { width: 56% !important; }
	.u-sp-w57per { width: 57% !important; }
	.u-sp-w58per { width: 58% !important; }
	.u-sp-w59per { width: 59% !important; }
	.u-sp-w60per { width: 60% !important; }
	.u-sp-w61per { width: 61% !important; }
	.u-sp-w62per { width: 62% !important; }
	.u-sp-w63per { width: 63% !important; }
	.u-sp-w64per { width: 64% !important; }
	.u-sp-w65per { width: 65% !important; }
	.u-sp-w66per { width: 66% !important; }
	.u-sp-w67per { width: 67% !important; }
	.u-sp-w68per { width: 68% !important; }
	.u-sp-w69per { width: 69% !important; }
	.u-sp-w70per { width: 70% !important; }
	.u-sp-w71per { width: 71% !important; }
	.u-sp-w72per { width: 72% !important; }
	.u-sp-w73per { width: 73% !important; }
	.u-sp-w74per { width: 74% !important; }
	.u-sp-w75per { width: 75% !important; }
	.u-sp-w76per { width: 76% !important; }
	.u-sp-w77per { width: 77% !important; }
	.u-sp-w78per { width: 78% !important; }
	.u-sp-w79per { width: 79% !important; }
	.u-sp-w80per { width: 80% !important; }
	.u-sp-w81per { width: 81% !important; }
	.u-sp-w82per { width: 82% !important; }
	.u-sp-w83per { width: 83% !important; }
	.u-sp-w84per { width: 84% !important; }
	.u-sp-w85per { width: 85% !important; }
	.u-sp-w86per { width: 86% !important; }
	.u-sp-w87per { width: 87% !important; }
	.u-sp-w88per { width: 88% !important; }
	.u-sp-w89per { width: 89% !important; }
	.u-sp-w90per { width: 90% !important; }
	.u-sp-w91per { width: 91% !important; }
	.u-sp-w92per { width: 92% !important; }
	.u-sp-w93per { width: 93% !important; }
	.u-sp-w94per { width: 94% !important; }
	.u-sp-w95per { width: 95% !important; }
	.u-sp-w96per { width: 96% !important; }
	.u-sp-w97per { width: 97% !important; }
	.u-sp-w98per { width: 98% !important; }
	.u-sp-w99per { width: 99% !important; }
	.u-sp-w100per{ width: 100% !important; }
	.u-sp-wauto  { width: auto !important; }
	
}



/*margin
---------------------------------------------------------- */
.u-m0    {margin:  0 !important;}

.u-mt0   {margin-top:  0 !important;}
.u-mt1   {margin-top:  1px !important;}
.u-mt2   {margin-top:  2px !important;}
.u-mt3   {margin-top:  3px !important;}
.u-mt4   {margin-top:  4px !important;}
.u-mt5   {margin-top:  5px !important;}
.u-mt10  {margin-top: 10px !important;}
.u-mt15  {margin-top: 15px !important;}
.u-mt20  {margin-top: 20px !important;}
.u-mt25  {margin-top: 25px !important;}
.u-mt30  {margin-top: 30px !important;}
.u-mt35  {margin-top: 35px !important;}
.u-mt40  {margin-top: 40px !important;}
.u-mt45  {margin-top: 45px !important;}
.u-mt50  {margin-top: 50px !important;}
.u-mt55  {margin-top: 55px !important;}
.u-mt60  {margin-top: 60px !important;}
.u-mt65  {margin-top: 65px !important;}
.u-mt70  {margin-top: 70px !important;}
.u-mt75  {margin-top: 75px !important;}
.u-mt80  {margin-top: 80px !important;}
.u-mt85  {margin-top: 85px !important;}
.u-mt90  {margin-top: 90px !important;}
.u-mt95  {margin-top: 95px !important;}
.u-mt100 {margin-top: 100px !important;}


@include mq('max') {
	
	.u-sp-mt0  {margin-top:  0 !important;}
	.u-sp-mt1  {margin-top:  1px !important;}
	.u-sp-mt2  {margin-top:  2px !important;}
	.u-sp-mt3  {margin-top:  3px !important;}
	.u-sp-mt4  {margin-top:  4px !important;}
	.u-sp-mt5  {margin-top:  5px !important;}
	.u-sp-mt10 {margin-top: 10px !important;}
	.u-sp-mt15 {margin-top: 15px !important;}
	.u-sp-mt20 {margin-top: 20px !important;}
	.u-sp-mt25 {margin-top: 25px !important;}
	.u-sp-mt30 {margin-top: 30px !important;}
	.u-sp-mt35 {margin-top: 35px !important;}
	.u-sp-mt40 {margin-top: 40px !important;}
	.u-sp-mt45 {margin-top: 45px !important;}
	.u-sp-mt50 {margin-top: 50px !important;}
	.u-sp-mt55  {margin-top:55px !important;}
	.u-sp-mt60 {margin-top: 60px !important;}
	.u-sp-mt65 {margin-top: 65px !important;}
	.u-sp-mt70 {margin-top: 70px !important;}
	.u-sp-mt75 {margin-top: 75px !important;}
	.u-sp-mt80 {margin-top: 80px !important;}
	.u-sp-mt85 {margin-top: 85px !important;}
	.u-sp-mt90 {margin-top: 90px !important;}
	.u-sp-mt95 {margin-top: 95px !important;}
	.u-sp-mt100 {margin-top: 100px !important;}
}


.u-mb0   {margin-bottom:  0 !important;}
.u-mb1   {margin-bottom:  1px !important;}
.u-mb2   {margin-bottom:  2px !important;}
.u-mb3   {margin-bottom:  3px !important;}
.u-mb4   {margin-bottom:  4px !important;}
.u-mb5   {margin-bottom:  5px !important;}
.u-mb10  {margin-bottom: 10px !important;}
.u-mb15  {margin-bottom: 15px !important;}
.u-mb20  {margin-bottom: 20px !important;}
.u-mb25  {margin-bottom: 25px !important;}
.u-mb30  {margin-bottom: 30px !important;}
.u-mb35  {margin-bottom: 35px !important;}
.u-mb40  {margin-bottom: 40px !important;}
.u-mb45  {margin-bottom: 45px !important;}
.u-mb50  {margin-bottom: 50px !important;}
.u-mb55  {margin-bottom: 55px !important;}
.u-mb60  {margin-bottom: 60px !important;}
.u-mb65  {margin-bottom: 65px !important;}
.u-mb70  {margin-bottom: 70px !important;}
.u-mb75  {margin-bottom: 75px !important;}
.u-mb80  {margin-bottom: 80px !important;}
.u-mb85  {margin-bottom: 85px !important;}
.u-mb90  {margin-bottom: 90px !important;}
.u-mb95  {margin-bottom: 95px !important;}
.u-mb100 {margin-bottom: 100px !important;}


@include mq('max') {
	
	.u-sp-mb0  {margin-bottom:  0 !important;}
	.u-sp-mb1  {margin-bottom:  1px !important;}
	.u-sp-mb2  {margin-bottom:  2px !important;}
	.u-sp-mb3  {margin-bottom:  3px !important;}
	.u-sp-mb4  {margin-bottom:  4px !important;}
	.u-sp-mb5  {margin-bottom:  5px !important;}
	.u-sp-mb10 {margin-bottom: 10px !important;}
	.u-sp-mb15 {margin-bottom: 15px !important;}
	.u-sp-mb20 {margin-bottom: 20px !important;}
	.u-sp-mb25 {margin-bottom: 25px !important;}
	.u-sp-mb30 {margin-bottom: 30px !important;}
	.u-sp-mb35 {margin-bottom: 35px !important;}
	.u-sp-mb40 {margin-bottom: 40px !important;}
	.u-sp-mb45 {margin-bottom: 45px !important;}
	.u-sp-mb50 {margin-bottom: 50px !important;}
	.u-sp-mb55  {margin-bottom: 55px !important;}
	.u-sp-mb60 {margin-bottom: 60px !important;}
	.u-sp-mb65 {margin-bottom: 65px !important;}
	.u-sp-mb70 {margin-bottom: 70px !important;}
	.u-sp-mb75 {margin-bottom: 75px !important;}
	.u-sp-mb80 {margin-bottom: 80px !important;}
	.u-sp-mb85 {margin-bottom: 85px !important;}
	.u-sp-mb90 {margin-bottom: 90px !important;}
	.u-sp-mb95 {margin-bottom: 95px !important;}
	.u-sp-mb100 {margin-bottom: 100px !important;}
}


.u-mr0   {margin-right: 0 !important;}
.u-mr5   {margin-right: 5px !important;}
.u-mr10  {margin-right: 10px !important;}
.u-mr15  {margin-right: 15px !important;}
.u-mr20  {margin-right: 20px !important;}
.u-mr25  {margin-right: 25px !important;}
.u-mr30  {margin-right: 30px !important;}
.u-mr35  {margin-right: 35px !important;}
.u-mr40  {margin-right: 40px !important;}
.u-mr45  {margin-right: 45px !important;}
.u-mr50  {margin-right: 50px !important;}
.u-mr55  {margin-right: 55px !important;}
.u-mr60  {margin-right: 60px !important;}
.u-mr65  {margin-right: 65px !important;}
.u-mr70  {margin-right: 70px !important;}
.u-mr75  {margin-right: 75px !important;}
.u-mr80  {margin-right: 80px !important;}
.u-mr85  {margin-right: 85px !important;}
.u-mr90  {margin-right: 90px !important;}
.u-mr95  {margin-right: 95px !important;}
.u-mr100 {margin-right: 100px !important;}


@include mq('max') {
	
	.u-sp-mr0  {margin-right:  0 !important;}
	.u-sp-mr5  {margin-right:  5px !important;}
	.u-sp-mr10 {margin-right: 10px !important;}
	.u-sp-mr15 {margin-right: 15px !important;}
	.u-sp-mr20 {margin-right: 20px !important;}
	.u-sp-mr25 {margin-right: 25px !important;}
	.u-sp-mr30 {margin-right: 30px !important;}
	.u-sp-mr35 {margin-right: 35px !important;}
	.u-sp-mr40 {margin-right: 40px !important;}
	.u-sp-mr45 {margin-right: 45px !important;}
	.u-sp-mr50 {margin-right: 50px !important;}
	.u-sp-mr55  {margin-right: 55px !important;}
	.u-sp-mr60 {margin-right: 60px !important;}
	.u-sp-mr65 {margin-right: 65px !important;}
	.u-sp-mr70 {margin-right: 70px !important;}
	.u-sp-mr75 {margin-right: 75px !important;}
	.u-sp-mr80 {margin-right: 80px !important;}
	.u-sp-mr85 {margin-right: 85px !important;}
	.u-sp-mr90 {margin-right: 90px !important;}
	.u-sp-mr95 {margin-right: 95px !important;}
	.u-sp-mr100 {margin-right: 100px !important;}
}


.u-ml0   {margin-left: 0 !important;}
.u-ml5   {margin-left: 5px !important;}
.u-ml10  {margin-left:10px !important;}
.u-ml15  {margin-left:15px !important;}
.u-ml20  {margin-left:20px !important;}
.u-ml25  {margin-left:25px !important;}
.u-ml30  {margin-left:30px !important;}
.u-ml35  {margin-left:35px !important;}
.u-ml40  {margin-left:40px !important;}
.u-ml45  {margin-left:45px !important;}
.u-ml50  {margin-left:50px !important;}
.u-ml55  {margin-left:55px !important;}
.u-ml60  {margin-left:60px !important;}
.u-ml65  {margin-left:65px !important;}
.u-ml70  {margin-left:70px !important;}
.u-ml75  {margin-left:75px !important;}
.u-ml80  {margin-left:80px !important;}
.u-ml85  {margin-left:85px !important;}
.u-ml90  {margin-left:90px !important;}
.u-ml95  {margin-left:95px !important;}
.u-ml100 {margin-left:100px !important;}


@include mq('max') {
	
	.u-sp-ml0  {margin-left:  0 !important;}
	.u-sp-ml5  {margin-left:  5px !important;}
	.u-sp-ml10 {margin-left: 10px !important;}
	.u-sp-ml15 {margin-left: 15px !important;}
	.u-sp-ml20 {margin-left: 20px !important;}
	.u-sp-ml25 {margin-left: 25px !important;}
	.u-sp-ml30 {margin-left: 30px !important;}
	.u-sp-ml35 {margin-left: 35px !important;}
	.u-sp-ml40 {margin-left: 40px !important;}
	.u-sp-ml45 {margin-left: 45px !important;}
	.u-sp-ml50 {margin-left: 50px !important;}
	.u-sp-ml55  {margin-left: 55px !important;}
	.u-sp-ml60 {margin-left: 60px !important;}
	.u-sp-ml65 {margin-left: 65px !important;}
	.u-sp-ml70 {margin-left: 70px !important;}
	.u-sp-ml75 {margin-left: 75px !important;}
	.u-sp-ml80 {margin-left: 80px !important;}
	.u-sp-ml85 {margin-left: 85px !important;}
	.u-sp-ml90 {margin-left: 90px !important;}
	.u-sp-ml95 {margin-left: 95px !important;}
	.u-sp-ml100 {margin-left: 100px !important;}
}

.u-m-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}


@include mq('max') {
	
	.u-sp-m-auto {
		margin-left: auto !important;
		margin-right: auto !important;
	}
	
}

