body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,button,textarea,p,blockquote,th,td{margin:0;padding:0;}table{border-collapse:collapse;border-spacing:0;}fieldset,img{border:0;}address,caption,cite,code,dfn,th,var{font-style:normal;font-weight:normal;}ol,ul{list-style:none;}caption,th{text-align:left;}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;}q:before,q:after{content:'';}abbr,acronym{border:0;font-variant:normal;}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit;}input,textarea,select{*font-size:100%;}legend{color:#000;}#yui3-css-stamp.cssreset{display:none;}img {vertical-align:top;font-size:0;line-height:0;}

img {
	max-width: 100%;
	vertical-align: middle;
}

a img {
	border: none;
	outline: none;
}

figure {
	margin: 0;
}

table {
	border: none;
	border-collapse: collapse;
	border-spacing: 0;
}

input,
textarea {
	text-align: left;
	vertical-align: top;
}

button {
	border: none;
}

button,
label {
	text-align: inherit;
	background-color: transparent;
	cursor: pointer;
}

button[disabled],
input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	vertical-align: middle;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;
	border: 0;
}

input[type="search"] {
	box-sizing: border-box;
}

input:-webkit-autofill {
	animation-name: onAutoFillStart;
	transition: background-color 50000s ease-in-out 0s;
}

small {
	font-size: inherit;
}

a {
	text-decoration: none;
	color: inherit;
}

input,
select,
textarea {
	font-size: 1.6rem;
}

:focus {
	outline: none;
}

* {
	box-sizing: border-box;
}

