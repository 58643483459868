@import "../foundation/setting";


.l-main {
	@include mq('max') {
		padding-top: 55px;
	}
}

.under .l-main {
	background: #fff;
}