@import "../foundation/setting";


.l-header {
	display: flex;
	position: relative;
	width: 100%;
	min-height: 110px;
	@include mq('max') {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		padding: 0 15px;
		min-height: 50px;
		background: #FCFCFC;
		h1 {
			width: 75px;
			img {
				height: auto;
			}
		}
	}
}
	
.l-header__inner	{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 940px;
	min-height: inherit;
	margin: 0 auto;
	padding: 10px 0;
}

.l-header__menu {
	@include mq('max') {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		width: 100%;
		height: 100%;
		background: #fff;
		visibility: hidden;
		opacity: 0;
		&[aria-hidden="false"] {
			visibility: visible;
			opacity: 1;
			transition: .2s;
			transition-property: visibility, opacity;
		}
	}
}
.l-header__menu-items {
	@include mq('min') {
		display: flex;
	}
}

.l-header__menu-item {
	@include mq('min') {
		&:not(:first-child) {
			margin-left: 12px;
		}
	}
	@include mq('max') {
		&:last-child {
			.item__link {
				border-bottom: 1px solid $_color_primary;
			}
		}
	}
	.item__link {
		@include mq('max') {
			display: flex;
			align-items: center;
			padding: 15px;
			font-weight: bold;
			border-top: 1px solid $_color_primary;
			img {
				display: none;
			}
		}
		.c-icon__arrow-right {
			@include mq('min') {
				display: none;
			}
			display: inline-block;
			margin-right: 10px;
			font-size: 10px;
			color: $_color_primary;
			&:before {
				display: inline-block;
				transform: scale(.7);
			}
		}
	}
	.item__title {
		@include mq('min') {
			display: none;
		}
	}
}

.l-header__submenu-items {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	left: 1px;
	width: calc(100% + 1px);
	padding: 20px 15px;
	@include mq('min') {
		display: none;
	}
}

.l-header__submenu-item {
	width: calc(50% + 1px);
	margin: -1px 0 0 -1px;
	.item__link {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px;
		text-align: center;
		border: 1px solid #ccc;
	}
}

.l-header__hamburger {
	@include mq('min') {
		display: none;
	}
	&[aria-expanded="true"] {
		.l-header__hamburger-bar {
			&:nth-child(1) {
				transform: translate(0) rotate(-45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: translate(0) rotate(45deg);
			}
		}
	}
}

.l-header__hamburger-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: $_color_primary;
}

.l-header__hamburger-bars {
	display: flex;
	align-items: center;
	position: relative;
	width: 16px;
	height: 12px;
	margin: auto;
}

.l-header__hamburger-bar {
	display: block;
	width: 100%;
	height: 2px;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: auto;
	margin-top: -1px;
	background-color: #fff;
	transition: .3s;
	transition-property: opacity, transform;
	&:nth-child(1) {
		transform: translateY(-5px);
	}
	&:nth-child(2) {
		transform: translateY(0px);
	}
	&:nth-child(3) {
		transform: translateY(5px);
	}
}
